import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBbh2_DA_tY5-uk3entDXUY_wdlbj61mNE",
  authDomain: "oses-beefreund.firebaseapp.com",
  databaseURL: "https://oses-beefreund-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "oses-beefreund",
  storageBucket: "oses-beefreund.appspot.com",
  messagingSenderId: "255764056104",
  appId: "1:255764056104:web:23f6d36d5897f208ad8d1d",
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()
const giris = fb.auth()

fb.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = giris.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export {
  fb,
  veri,
  fonksiyon,
  depo,
  giris,
}
